import { LanguagesApiService } from './../services/languages-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { SharedActions } from './actions';
import { catchError, map, of, switchMap } from 'rxjs';
import { DialogsActions } from 'src/app/modules/dialogs/store/actions';
import { AppState } from 'src/app/core/store/state';
import { Store } from '@ngrx/store';

@Injectable()
export class FeatureEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private languagesApiService: LanguagesApiService,
  ) {}

  getLanguages$ = createEffect(() =>
    this.actions$.pipe(ofType(SharedActions.getLanguages)).pipe(
      map(() => {
        this.store.dispatch(SharedActions.getContentLanguages());
        return SharedActions.getInterfaceLanguages();
      }),
    ),
  );

  getContentLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.getContentLanguages),
      switchMap(() => {
        return this.languagesApiService.getContentLanguages$().pipe(
          map((languages) => {
            return SharedActions.setContentLanguages({ languages });
          }),
          catchError((response) => {
            return of(SharedActions.getContentLanguagesFail());
          }),
        );
      }),
    ),
  );

  getInterfaceLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.getInterfaceLanguages),
      switchMap(() => {
        return this.languagesApiService.getInterfaceLanguages$().pipe(
          map((languages) => {
            return SharedActions.setInterfaceLanguages({ languages });
          }),
          catchError((response) => {
            return of(SharedActions.getInterfaceLanguagesFail());
          }),
        );
      }),
    ),
  );
}
