@if (form) {
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title pull-left">{{ titleKey | transloco }}</h5>
      <mat-icon (click)="cancel()" class="">{{ 'close' }}</mat-icon>
    </div>

    <div class="modal-body" [formGroup]="form">
      <div class="row">
        <div class="col">
          <app-input
            formControlName="search"
            placeholder="{{ 'placeholders.search' | transloco }}"
          ></app-input>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <hr />
        </div>
      </div>
      <div class="row mt-3 filtered-list-container">
        <div class="col">
          <ul class="filtered-list">
            @for (option of filteredOptions$ | async; track option) {
              <li>
                <mat-checkbox
                  [ngClass]="{
                    'option-checked': isOptionChecked(option) && !isOptionIndeterminate(option)
                  }"
                  (change)="onItemChecked($event, option)"
                  [checked]="isOptionChecked(option)"
                  [indeterminate]="isOptionIndeterminate(option)"
                >
                  {{ option[this.optionLabelKey] }}
                </mat-checkbox>
              </li>
            }
          </ul>

          @if ((filteredOptions$ | async)?.length === 0) {
            <p class="text-center">
              {{ 'text.noResultsFound' | transloco }}
            </p>
          }
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <span class="itemsToBeModified">
        {{ countItemsWillBeUpdatedKey | transloco: { count: itemsToBeModifiedCount } }}
      </span>

      <app-button
        [type]="btnTypes.Flat"
        label="{{ 'buttons.save' | transloco }}"
        [size]="btnSizes.Basic"
        [disabled]="!checkedOption"
        (onClick)="confirm()"
      ></app-button>
      <app-button
        [type]="btnTypes.Stroked"
        label="{{ 'buttons.cancel' | transloco }}"
        [size]="btnSizes.Basic"
        (onClick)="cancel()"
      ></app-button>
    </div>
  </div>
}
