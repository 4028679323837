export const constants = {
  requestTimeBeforeSpinner: 50,
  tableColumnTextLimit: 150,
  urlKeysForNonBlockingSpinner: ['generate'],
  tooltipHideDelay: 300,
  defaultDataTablePageLength: 10,
  fileUploaderAcceptedFormats: ['.docx'],
  baselineDocsFileUploaderAcceptedFormats: ['.docx', '.pdf'],
  fileUploaderAcceptedFormatsTypes: [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
  textareaMaxLength: 2000,
  supportEmail: 'support@autolex.ai',
  supportUrl: 'https://www.autolex.ai/support',
  authTypeKey: 'AUTH_TYPE',
  fields: {
    documentId: 'uniqueId',
    documentType: 'type',
    documentUserPosition: 'userPositionId',
    documentCounterparties: 'parties',
    documentStatus: 'status',
    documentLanguage: 'languageId',
    clauseId: 'id',
    clauseDocumentTypes: 'documentTypes',
    clauseUserPositions: 'positions',
    clauseLanguage: 'languageId',
    questionId: 'id',
    questionDocumentTypes: 'documentTypes',
    questionUserPositions: 'positions',
    questionLanguage: 'languageId',
  },
};

export const ALL_DROPDOWN_ITEM = { id: 'ALL', name: 'All' };
