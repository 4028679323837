function isOptionIndeterminate(
  option: any,
  valueKey: string,
  mainItems: any[],
  mainItemIdKey: string,
  selectedMainItemsIds: string[],
  fieldToBeModifiedName: string,
  checkedOption: any | undefined,
): boolean {
  let optionValue = option[valueKey];
  let mainItemWithSuchOptionSelected = isMainItemWithSuchOptionSelected(
    mainItems,
    mainItemIdKey,
    selectedMainItemsIds,
    fieldToBeModifiedName,
    optionValue,
  );
  let selectedMainItemsHaveSameOptionValue = isSelectedMainItemsHaveSameOptionValue(
    mainItems,
    mainItemIdKey,
    selectedMainItemsIds,
    fieldToBeModifiedName,
  );

  if (checkedOption || selectedMainItemsIds.length === 1 || selectedMainItemsHaveSameOptionValue) {
    return false;
  }

  return mainItemWithSuchOptionSelected;
}

function isOptionChecked(
  option: any,
  valueKey: string,
  mainItems: any[],
  mainItemIdKey: string,
  selectedMainItemsIds: string[],
  fieldToBeModifiedName: string,
  checkedOption: any | undefined,
): boolean {
  let optionValue = option[valueKey];
  let mainItemWithSuchOptionSelected = isMainItemWithSuchOptionSelected(
    mainItems,
    mainItemIdKey,
    selectedMainItemsIds,
    fieldToBeModifiedName,
    optionValue,
  );
  let selectedMainItemsHaveSameOptionValue = isSelectedMainItemsHaveSameOptionValue(
    mainItems,
    mainItemIdKey,
    selectedMainItemsIds,
    fieldToBeModifiedName,
  );

  if (checkedOption) {
    return checkedOption?.[valueKey] === optionValue;
  }

  if (
    (selectedMainItemsIds.length === 1 && mainItemWithSuchOptionSelected) ||
    (selectedMainItemsHaveSameOptionValue && mainItemWithSuchOptionSelected)
  ) {
    return true;
  }

  return mainItemWithSuchOptionSelected;
}

function isMultipleOptionIndeterminate(
  option: any,
  valueKey: string,
  mainItems: any[],
  mainItemIdKey: string,
  selectedMainItemsIds: string[],
  fieldToBeModifiedName: string,
  checkedOptions: any | undefined,
): boolean {
  let optionValue = option[valueKey];
  let mainItemWithSuchOptionSelected = isMainItemWithSuchMultipleOptionSelected(
    mainItems,
    mainItemIdKey,
    selectedMainItemsIds,
    fieldToBeModifiedName,
    optionValue,
  );
  let selectedMainItemsHaveSameOptionValue = isSelectedMainItemsHaveSameMultipleOptionValue(
    mainItems,
    mainItemIdKey,
    selectedMainItemsIds,
    fieldToBeModifiedName,
  );
  let allSelectedMainItemsHasSuchOptionValue = isAllSelectedMainItemsHasSuchOptionValue(
    mainItems,
    optionValue,
    fieldToBeModifiedName,
  );

  if (
    checkedOptions ||
    selectedMainItemsIds.length === 1 ||
    selectedMainItemsHaveSameOptionValue ||
    allSelectedMainItemsHasSuchOptionValue
  ) {
    return false;
  }

  return mainItemWithSuchOptionSelected;
}

function isMultipleOptionChecked(
  option: any,
  valueKey: string,
  mainItems: any[],
  mainItemIdKey: string,
  selectedMainItemsIds: string[],
  fieldToBeModifiedName: string,
  checkedOptions: any | undefined,
): boolean {
  let optionValue = option[valueKey];
  let mainItemWithSuchMultipleOptionSelected = isMainItemWithSuchMultipleOptionSelected(
    mainItems,
    mainItemIdKey,
    selectedMainItemsIds,
    fieldToBeModifiedName,
    optionValue,
  );
  let selectedMainItemsHaveSameOptionValue = isSelectedMainItemsHaveSameMultipleOptionValue(
    mainItems,
    mainItemIdKey,
    selectedMainItemsIds,
    fieldToBeModifiedName,
  );
  let allSelectedMainItemsHasSuchOptionValue = isAllSelectedMainItemsHasSuchOptionValue(
    mainItems,
    optionValue,
    fieldToBeModifiedName,
  );

  if (checkedOptions) {
    return checkedOptions?.map((item: any) => item[valueKey]).includes(optionValue).length > 0;
  }

  if (
    (selectedMainItemsIds.length === 1 && mainItemWithSuchMultipleOptionSelected) ||
    (selectedMainItemsHaveSameOptionValue && mainItemWithSuchMultipleOptionSelected) ||
    allSelectedMainItemsHasSuchOptionValue
  ) {
    return true;
  }

  return mainItemWithSuchMultipleOptionSelected;
}

function isAllSelectedMainItemsHasSuchOptionValue(
  mainItems: any[],
  optionValue: any,
  fieldToBeModifiedName: string,
): boolean {
  let isEveryMainItemHasSuchOptionValue = mainItems.every((item) =>
    item[fieldToBeModifiedName].includes(optionValue),
  );
  return isEveryMainItemHasSuchOptionValue;
}

function isMainItemWithSuchOptionSelected(
  mainItems: any[],
  mainItemIdKey: string,
  selectedMainItemsIds: string[],
  fieldToBeModifiedName: string,
  optionValue: any,
): boolean {
  let isMainItemWithSuchOptionSelected =
    mainItems
      .filter((item) => selectedMainItemsIds.includes(item[mainItemIdKey] + ''))
      .filter((item) => item[fieldToBeModifiedName] === optionValue).length > 0;
  return isMainItemWithSuchOptionSelected;
}

function isSelectedMainItemsHaveSameOptionValue(
  mainItems: any[],
  mainItemIdKey: string,
  selectedMainItemsIds: string[],
  fieldToBeModifiedName: string,
): boolean {
  let selectedMainItemsOptionValues = mainItems
    .filter((item) => selectedMainItemsIds.includes(item[mainItemIdKey] + ''))
    .map((item) => item[fieldToBeModifiedName]);

  return Array.from(new Set(selectedMainItemsOptionValues)).length === 1;
}

function isMainItemWithSuchMultipleOptionSelected(
  mainItems: any[],
  mainItemIdKey: string,
  selectedMainItemsIds: string[],
  fieldToBeModifiedName: string,
  optionValue: any,
): boolean {
  let filteredByIds = mainItems.filter((item) =>
    selectedMainItemsIds.includes(item[mainItemIdKey] + ''),
  );

  let isMainItemWithSuchOptionSelected =
    filteredByIds.filter((item) => item[fieldToBeModifiedName].includes(optionValue)).length > 0;
  return isMainItemWithSuchOptionSelected;
}

function isSelectedMainItemsHaveSameMultipleOptionValue(
  mainItems: any[],
  mainItemIdKey: string,
  selectedMainItemsIds: string[],
  fieldToBeModifiedName: string,
): boolean {
  let selectedMainItemsOptionValuesArrays = [...mainItems]
    .filter((item) => selectedMainItemsIds.includes(item[mainItemIdKey] + ''))
    .map((item) => item[fieldToBeModifiedName]);

  if (selectedMainItemsOptionValuesArrays.length < 2) {
    return true;
  }

  let firstElement = Object.assign([...selectedMainItemsOptionValuesArrays]?.[0], {});
  let firstValue = JSON.stringify([...firstElement]?.sort((a: any, b: any) => a - b));

  let isEveryArrayItemsTheSame = selectedMainItemsOptionValuesArrays.every((item) => {
    return (
      JSON.stringify(item.length > 1 ? [...item].sort((a: any, b: any) => a - b) : [...item]) ===
      firstValue
    );
  });

  return isEveryArrayItemsTheSame;
}

export {
  isOptionIndeterminate,
  isOptionChecked,
  isMultipleOptionIndeterminate,
  isMultipleOptionChecked,
};
