import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { debounceTime, Observable, of, Subject, tap } from 'rxjs';
import { AppState } from 'src/app/core/store/state';
import { AppButtonSize, AppButtonType } from 'src/app/shared/shared.model';
import { isOptionChecked, isOptionIndeterminate } from '../bulk-edit.utils';
import { MatCheckbox } from '@angular/material/checkbox';

@UntilDestroy()
@Component({
  selector: 'app-bulk-edit-single-value-property',
  templateUrl: './app-bulk-edit-single-value-property.component.html',
  styleUrl: './app-bulk-edit-single-value-property.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBulkEditSingleValuePropertyComponent implements OnInit {
  @Input() titleKey: string = '';
  @Input() countItemsWillBeUpdatedKey: string = '';
  @Input() mainItems: any[] = [];
  @Input() options: any[] = [];
  @Input() fieldToBeModifiedName: string = '';
  @Input() selectedMainItemsIds: string[] = [];
  @Input() mainItemIdKey: string = 'id';
  @Input() optionLabelKey: string = 'name';
  @Input() optionValueKey: string = 'id';

  continue$: Subject<BulkEditValueModel> = new Subject();
  cancel$: Subject<void> = new Subject();

  public form: FormGroup | undefined;
  private searchFormControl!: FormControl<any>;

  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;

  public filteredOptions$: Observable<any[]> = of(this.options);
  public checkedOption: any | undefined;

  constructor(
    private readonly fb: FormBuilder,
    private store$: Store<AppState>,
    private bsModalRef: BsModalRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.initFilteredOptions();
  }

  initForm() {
    this.form = this.fb.group({
      search: [''],
    });

    this.initControls();
    this.initSubscriptions();
    this.cdr.markForCheck();
  }

  initSubscriptions() {
    this.searchFormControl?.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(10),
        tap((value: string) => {
          if (!value || value === '') {
            this.filteredOptions$ = of(this.options);
          } else {
            this.filteredOptions$ = of(
              this.options.filter((item) =>
                item[this.optionLabelKey].toLowerCase().includes(value.toLowerCase()),
              ),
            );
          }

          this.cdr.markForCheck();
        }),
      )
      .subscribe();
  }

  initControls() {
    this.searchFormControl = this.form?.get('search') as FormControl;
  }

  initFilteredOptions(): void {
    this.filteredOptions$ = of(this.options);
  }

  onItemChecked(event: any, option: any): void {
    this.checkedOption = option;

    // fix case we have indeterminate states and first click
    if (
      event.checked === false &&
      this.checkedOption[this.optionValueKey] === option[this.optionValueKey]
    ) {
      let cb = event.source as MatCheckbox;
      cb.toggle();
    }

    this.cdr.markForCheck();
  }

  isOptionChecked(option: any): boolean {
    return isOptionChecked(
      option,
      this.optionValueKey,
      this.mainItems,
      this.mainItemIdKey,
      this.selectedMainItemsIds,
      this.fieldToBeModifiedName,
      this.checkedOption,
    );
  }

  isOptionIndeterminate(option: any): boolean {
    return isOptionIndeterminate(
      option,
      this.optionValueKey,
      this.mainItems,
      this.mainItemIdKey,
      this.selectedMainItemsIds,
      this.fieldToBeModifiedName,
      this.checkedOption,
    );
  }

  public confirm(): void {
    let model = {
      mainItemIdKey: this.mainItemIdKey,
      checkedOptionValue: this.checkedOption[this.optionValueKey],
      fieldToBeModifiedName: this.fieldToBeModifiedName,
      selectedMainItemsIds: this.selectedMainItemsIds,
    } as BulkEditValueModel;

    this.continue$.next(model);
    this.continue$.complete();
    this.bsModalRef.hide();
  }

  public cancel(): void {
    this.cancel$.next();
    this.cancel$.complete();
    this.bsModalRef.hide();
  }

  get itemsToBeModifiedCount(): number {
    return this.selectedMainItemsIds?.length || 0;
  }
}

export interface BulkEditValueModel {
  mainItemIdKey: string;
  checkedOptionValue: any;
  fieldToBeModifiedName: string;
  selectedMainItemsIds: string[];
}
