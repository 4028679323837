import { dataTableEvents } from 'src/app/core/constants/data-table-events';

function atLeastOneSelectedOnCurrentPage(currentPageDataIds: any[], selectedIds: any[]): boolean {
  return currentPageDataIds.some((item: any) => selectedIds.includes(item));
}

function allSelectedOnCurrentPage(currentPageDataIds: any[], selectedIds: any[]): boolean {
  return currentPageDataIds.every((val) => selectedIds.includes(val));
}

function processThreeDotsMenu(event: any) {
  const menus = document.getElementsByClassName('dropdown-content');

  for (let i = 0; i < menus.length; i++) {
    menus[i].classList.remove('show');
  }

  if (event.target.id === 'threeDots') {
    if (event.target.nextSibling.nextSibling.classList.contains('show')) {
      event.target.nextSibling.nextSibling.classList.remove('show');
    } else {
      event.target.nextSibling.nextSibling.classList.toggle('show');
    }
  }
}

function processSelectItemClick(event: any, selectedIds: string[]): string[] {
  const itemId = event.target.getAttribute(dataTableEvents.common.checkedId);

  if (event.target.checked) {
    selectedIds = [...selectedIds, itemId];
  } else {
    selectedIds = selectedIds.filter((id: string) => {
      return id !== itemId;
    });
  }

  return selectedIds;
}

function processSelectAllClick(event: any, selectedIds: string[]): string[] {
  $('input#selectItem').prop('checked', event.target.checked);

  let selectedIdsOnCurrentPage = $('input#selectItem')
    .map(function () {
      return this.getAttribute(dataTableEvents.common.checkedId);
    })
    .get();

  if (event.target.checked === true) {
    selectedIds = [...new Set([...selectedIds, ...selectedIdsOnCurrentPage])];
  } else {
    selectedIds = selectedIds.filter((id: string) => {
      return !selectedIdsOnCurrentPage.includes(id);
    });
  }

  return selectedIds;
}

function selectCheckBoxesRelatedToSelectedIds(selectedIds: string[]): void {
  $('input#selectItem')
    .get()
    .forEach((item: any) => {
      item.checked = selectedIds.includes(item.getAttribute(dataTableEvents.common.checkedId));
    });
}

function processSelectLogic(
  apiInstance: DataTables.Api | undefined,
  selectFieldName: string,
  selectedIds: string[],
): void {
  const currentPageData = apiInstance?.rows({ page: 'current' }).data().toArray();
  const currentPageDataIds = currentPageData?.map((item: any) => item[selectFieldName]?.toString());
  const selectAllCheckbox = document.getElementById('selectAll');

  if (!selectAllCheckbox || !currentPageData || !currentPageDataIds) {
    return;
  }

  if (allSelectedOnCurrentPage(currentPageDataIds, selectedIds)) {
    (selectAllCheckbox as HTMLInputElement).indeterminate = false;
    (selectAllCheckbox as HTMLInputElement).checked = true;
  } else if (atLeastOneSelectedOnCurrentPage(currentPageDataIds, selectedIds)) {
    (selectAllCheckbox as HTMLInputElement).indeterminate = true;
  } else {
    (selectAllCheckbox as HTMLInputElement).indeterminate = false;
    (selectAllCheckbox as HTMLInputElement).checked = false;
  }

  selectCheckBoxesRelatedToSelectedIds(selectedIds);
}

export {
  atLeastOneSelectedOnCurrentPage,
  allSelectedOnCurrentPage,
  processThreeDotsMenu,
  processSelectItemClick,
  processSelectAllClick,
  selectCheckBoxesRelatedToSelectedIds,
  processSelectLogic,
};
