import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { debounceTime, Observable, of, Subject, tap } from 'rxjs';
import { AppState } from 'src/app/core/store/state';
import { AppButtonSize, AppButtonType } from 'src/app/shared/shared.model';
import { MatCheckbox } from '@angular/material/checkbox';
import { isMultipleOptionChecked, isMultipleOptionIndeterminate } from '../bulk-edit.utils';

@UntilDestroy()
@Component({
  selector: 'app-bulk-edit-multiple-values-property',
  templateUrl: './app-bulk-edit-multiple-values-property.component.html',
  styleUrl: './app-bulk-edit-multiple-values-property.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBulkEditMultipleValuesPropertyComponent implements OnInit {
  @Input() titleKey: string = '';
  @Input() countItemsWillBeUpdatedKey: string = '';
  @Input() mainItems: any[] = [];
  @Input() options: any[] = [];
  @Input() fieldToBeModifiedName: string = '';
  @Input() selectedMainItemsIds: string[] = [];
  @Input() mainItemIdKey: string = 'id';
  @Input() optionLabelKey: string = 'name';
  @Input() optionValueKey: string = 'id';

  continue$: Subject<BulkEditSingleValueModel> = new Subject();
  cancel$: Subject<void> = new Subject();

  public form: FormGroup | undefined;
  private searchFormControl!: FormControl<any>;

  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;

  public filteredOptions$: Observable<any[]> = of(this.options);
  public checkedOptions: any[] | undefined;

  constructor(
    private readonly fb: FormBuilder,
    private store$: Store<AppState>,
    private bsModalRef: BsModalRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.initFilteredOptions();
  }

  initForm() {
    this.form = this.fb.group({
      search: [''],
    });

    this.initControls();
    this.initSubscriptions();
    this.cdr.markForCheck();
  }

  initSubscriptions() {
    this.searchFormControl?.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(10),
        tap((value: string) => {
          if (!value || value === '') {
            this.filteredOptions$ = of(this.options);
          } else {
            this.filteredOptions$ = of(
              this.options.filter((item) =>
                item[this.optionLabelKey].toLowerCase().includes(value.toLowerCase()),
              ),
            );
          }

          this.cdr.markForCheck();
        }),
      )
      .subscribe();
  }

  initControls() {
    this.searchFormControl = this.form?.get('search') as FormControl;
  }

  initFilteredOptions(): void {
    this.filteredOptions$ = of(this.options);
  }

  onItemChecked(event: any, option: any): void {
    if (typeof this.checkedOptions === 'undefined') {
      this.checkedOptions = [option];

      // fix case we have indeterminate states and first click
      if (
        event.checked === false &&
        this.checkedOptions.find(
          (item) => item[this.optionValueKey] === option[this.optionValueKey],
        ).length !== 0
      ) {
        let cb = event.source as MatCheckbox;
        setTimeout(() => {
          cb.toggle();
          this.cdr.markForCheck();
        });
      }
    } else {
      // check
      if (event.checked === true) {
        this.checkedOptions.push(option);
      } else {
        // uncheck
        let indexToBeDeleted = this.checkedOptions.findIndex(
          (item) => item[this.optionValueKey] !== option[this.optionValueKey],
        );

        this.checkedOptions.splice(indexToBeDeleted, 1);
      }
    }

    this.cdr.markForCheck();
  }

  isMultipleOptionChecked(option: any): boolean {
    return isMultipleOptionChecked(
      option,
      this.optionValueKey,
      this.mainItems,
      this.mainItemIdKey,
      this.selectedMainItemsIds,
      this.fieldToBeModifiedName,
      this.checkedOptions,
    );
  }

  isMultipleOptionIndeterminate(option: any): boolean {
    return isMultipleOptionIndeterminate(
      option,
      this.optionValueKey,
      this.mainItems,
      this.mainItemIdKey,
      this.selectedMainItemsIds,
      this.fieldToBeModifiedName,
      this.checkedOptions,
    );
  }

  public confirm(): void {
    let model = {
      mainItemIdKey: this.mainItemIdKey,
      checkedOptionValue: this.checkedOptions?.map((item) => item[this.optionValueKey]),
      fieldToBeModifiedName: this.fieldToBeModifiedName,
      selectedMainItemsIds: this.selectedMainItemsIds,
    } as BulkEditSingleValueModel;

    this.continue$.next(model);
    this.continue$.complete();
    this.bsModalRef.hide();
  }

  public cancel(): void {
    this.cancel$.next();
    this.cancel$.complete();
    this.bsModalRef.hide();
  }

  get itemsToBeModifiedCount(): number {
    return this.selectedMainItemsIds?.length || 0;
  }
}

export interface BulkEditSingleValueModel {
  mainItemIdKey: string;
  checkedOptionValue: any;
  fieldToBeModifiedName: string;
  selectedMainItemsIds: string[];
}
