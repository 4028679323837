import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataTableDirective } from 'angular-datatables';
import { BehaviorSubject, Subject, take } from 'rxjs';
import { constants } from 'src/app/core/constants/constants';
import { Criterion, Pagination, SearchItemModel } from 'src/app/core/services/base-event-service';
import {
  ClientSideSearchService,
  FilterRequestItem,
} from 'src/app/core/services/client-side-search.service';
import { processCommonMenuItems } from 'src/app/core/services/table-menu-process.service';
import {
  processSelectAllClick,
  processSelectItemClick,
  processSelectLogic,
  processThreeDotsMenu,
} from './client-side-data-table.utils';

@UntilDestroy()
@Component({
  selector: 'app-client-side-data-table',
  templateUrl: './client-side-data-table.component.html',
})
export class ClientSideDataTableComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input()
  id!: string;
  @Input()
  columns!: DataTables.ColumnSettings[];
  @Input() selectFieldName: string = 'uniqueId';
  @Input() sort: any;
  @Input() eventService: any;
  @Input() data: any;
  @Input() paging: any = true;
  @Input() ordering: any = true;
  @Input() refreshIt: BehaviorSubject<any> | undefined;

  @ViewChild(DataTableDirective, { static: false })
  dtOptions!: DataTables.Settings;

  dtTrigger = new Subject<any>();

  selectedIds: string[] = [];
  filterItems!: SearchItemModel[];

  listenerFn: (() => void) | undefined;
  tableTranslationsData: {} | undefined;
  apiInstance: DataTables.Api | undefined;

  constructor(
    private renderer: Renderer2,
    private clientSideSearchService: ClientSideSearchService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.initTranslations();
    this.initSubscriptions();
    this.dtOptions = {
      ...this.getDTOptions(),
      ajax: (dataTablesParameters: any, callback) => {
        this.ajaxFunction(dataTablesParameters, callback);
      },
    };
  }

  ngOnDestroy() {
    if (this.listenerFn) {
      this.listenerFn();
    }

    const datatable = $(`#${this.id}`).DataTable();
    datatable.destroy();

    this.dtTrigger.unsubscribe();
    this.eventService.dataWasLoaded = false;
  }

  rerender(data?: any): void {
    const that = this;
    const datatable = $(`#${this.id}`).DataTable();
    datatable.destroy();

    if (data) {
      this.eventService.setData(data);
    }

    this.apiInstance = $(`#${this.id}`).DataTable({
      ...this.getDTOptions(),
      ajax: (dataTablesParameters: any, callback) => {
        this.ajaxFunction(dataTablesParameters, callback, data);
      },
    });

    this.apiInstance.on('page', function (data) {
      processSelectLogic(that.apiInstance, that.selectFieldName, that.selectedIds);
    });
  }

  ajaxFunction(dataTablesParameters: any, callback: any, data?: any): any {
    this.eventService.dataWasLoaded = true;
    callback({
      data: data || this.data,
    });
  }

  getDTOptions(): any {
    return {
      destroy: true,
      paging: this.paging,
      pagingType: 'full_numbers',
      pageLength: this.getPageLength(),
      ordering: this.ordering,
      order: this.sort,
      serverSide: false,
      processing: false,
      stateSave: false,
      searching: false,
      columns: this.columns,
      language: this.tableTranslationsData,
      stateSaveParams: (data: any) => {
        const pagination = {
          start: data.oSavedState.start,
          length: data.oSavedState.length,
        };

        this.eventService?.savePagination(pagination);
      },
    };
  }

  getPageLength(): number {
    const preSavedPagination: Pagination = this.eventService?.getPagination();

    if (preSavedPagination?.length) {
      return preSavedPagination.length;
    }

    return constants.defaultDataTablePageLength;
  }

  ngAfterViewInit(): void {
    this.listenerFn = this.renderer.listen('document', 'click', (event) => {
      processThreeDotsMenu(event);
      this.processSelectors(event);

      processCommonMenuItems(this.eventService, event);

      event.stopPropagation();
    });

    this.dtTrigger.next(null);
  }

  processSelectors(event: any) {
    if (event.target.id === 'selectAll') {
      this.selectedIds = processSelectAllClick(event, this.selectedIds);
    }

    if (event.target.id === 'selectItem') {
      this.selectedIds = processSelectItemClick(event, this.selectedIds);
    }

    processSelectLogic(this.apiInstance, this.selectFieldName, this.selectedIds);
    this.eventService.onSelected(this.selectedIds);
  }

  private initTranslations(): void {
    this.translocoService.langChanges$.pipe(untilDestroyed(this)).subscribe(() => {
      this.translocoService
        .selectTranslation()
        .pipe(take(1), untilDestroyed(this))
        .subscribe((data) => {
          this.tableTranslationsData = {
            info: data['dataTableLanguageSettings.info'],
            infoEmpty: data['dataTableLanguageSettings.infoEmpty'],
            lengthMenu: data['dataTableLanguageSettings.lengthMenu'],
            zeroRecords: data['dataTableLanguageSettings.zeroRecords'],
            paginate: {
              first: data['dataTableLanguageSettings.paginate.first'],
              last: data['dataTableLanguageSettings.paginate.last'],
              next: data['dataTableLanguageSettings.paginate.next'],
              previous: data['dataTableLanguageSettings.paginate.previous'],
            },
          };
        });
      this.rerender();
    });
  }

  private initSubscriptions(): void {
    this.refreshIt?.pipe(untilDestroyed(this)).subscribe((refresh: any) => {
      if (refresh) {
        this.columns = refresh;
        $(`#${this.id}`).DataTable().destroy();
        this.rerender();
      }
    });

    this.eventService
      .getFilterConditionsSubject()
      .pipe(untilDestroyed(this))
      .subscribe((filterItems: SearchItemModel[]) => {
        this.filterItems = filterItems.map((item: SearchItemModel) => {
          item.searchValue = item.searchValue?.toString();
          return item;
        });
        this.processFilters();
      });

    this.eventService?.resetSelections$
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe((reset: boolean) => {
        reset && this.resetSelections();
      });

    this.eventService?.selectAll$
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe((select: boolean) => {
        select && this.selectAll();
      });
  }

  private resetSelections(): void {
    const selectAllCheckbox = document.getElementById('selectAll');

    this.selectedIds = [];

    if (selectAllCheckbox) {
      (selectAllCheckbox as HTMLInputElement).indeterminate = false;
      (selectAllCheckbox as HTMLInputElement).checked = false;
    }

    $('input#selectItem').prop('checked', false);
    // this.eventService.onSelected(this.selectedIds);
  }

  private selectAll(): void {
    this.selectedIds =
      this.apiInstance
        ?.data()
        .toArray()
        .map((item: any) => {
          return item[this.selectFieldName] + '';
        }) || this.selectedIds;

    //this.eventService.onSelected(this.selectedIds);
  }

  private processFilters(): void {
    if (this.filterItems?.length > 0) {
      let dataFiltered = Object.assign(this.data);

      this.filterItems.forEach((filterItem) => {
        const criterion = this.eventService
          ?.getFilterCriterions()
          .find((filterCriterion: Criterion) => filterItem.criterion === filterCriterion.id);

        dataFiltered = this.clientSideSearchService.doFilterItems(
          {
            propertyNames: criterion.data,
            propertyValue: filterItem.searchValue,
            operator: filterItem.operator,
          } as FilterRequestItem,
          dataFiltered,
          criterion.type,
        );
      });

      this.rerender(dataFiltered);
    } else {
      setTimeout(() => {
        this.rerender(this.data);
      });
    }
  }
}

export class DataTableItem {
  title!: string;
  event!: string;
  icon!: string;
}
