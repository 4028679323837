import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SharedFeature } from './selectors';
import { LanguageDto } from '../services/languages-api.service';

export const SharedActions = createActionGroup({
  source: SharedFeature,
  events: {
    'Get Languages': emptyProps(),
    'Get Content Languages': emptyProps(),
    'Get Content Languages Fail': emptyProps(),
    'Get Interface Languages': emptyProps(),
    'Get Interface Languages Fail': emptyProps(),
    'Set Content Languages': props<{ languages: LanguageDto[] }>(),
    'Set Interface Languages': props<{ languages: LanguageDto[] }>(),
  },
});
