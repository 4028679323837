import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { environment } from 'src/environment/environment';
import { inject } from '@angular/core';

export class ApiService {
  public baseUrl = environment.baseUrl;
  http = inject(HttpClient);

  private setHeaders(): HttpHeaders {
    return new HttpHeaders().set('Content-Type', 'application/json');
  }

  protected get<T>(path: string, params?: HttpParams): Observable<T> {
    return this.http
      .get<T>(this.baseUrl + path, { headers: this.setHeaders(), params })
      .pipe(catchError(this.formatErrors));
  }

  protected put<T>(path: string, body: Object = {}): Observable<T> {
    body = this.extendBodyByLanguage(body);

    return this.http
      .put<T>(this.baseUrl + path, JSON.stringify(body), { headers: this.setHeaders() })
      .pipe(catchError(this.formatErrors));
  }

  protected patch<T>(path: string, body: Object = {}): Observable<T> {
    body = this.extendBodyByLanguage(body);

    return this.http
      .patch<T>(this.baseUrl + path, JSON.stringify(body), { headers: this.setHeaders() })
      .pipe(catchError(this.formatErrors));
  }

  protected post<T>(path: string, body: Object = {}): Observable<T> {
    // body = this.extendBodyByLanguage(body);

    return this.http
      .post<T>(this.baseUrl + path, JSON.stringify(body), { headers: this.setHeaders() })
      .pipe(catchError(this.formatErrors));
  }

  protected postForm<T>(path: string, formData: FormData): Observable<any> {
    //body = this.extendBodyByLanguage(body);

    return this.http.post<T>(this.baseUrl + path, formData, {}).pipe(catchError(this.formatErrors));
  }

  protected postWithResponseText<T>(path: string, body: Object = {}): Observable<T> {
    body = this.extendBodyByLanguage(body);

    return this.http
      .post<T>(this.baseUrl + path, JSON.stringify(body), {
        headers: this.setHeaders(),
        responseType: 'text' as 'json',
      })
      .pipe(catchError(this.formatErrors));
  }

  protected postFormData<T>(path: string, body: Object = {}): Observable<T> {
    return this.http.post<T>(this.baseUrl + path, body).pipe(catchError(this.formatErrors));
  }

  protected delete<T>(path: string): Observable<T> {
    return this.http
      .delete<T>(this.baseUrl + path, { headers: this.setHeaders() })
      .pipe(catchError(this.formatErrors));
  }

  private formatErrors(error: any): Observable<never> {
    // todo: errors processing logic
    throw error;
  }

  // todo: delete it when all endpoints will be refactored
  private extendBodyByLanguage(body: any): any {
    if (Object.keys(body).indexOf('properties') !== -1) {
      var tempBody = JSON.parse(JSON.stringify(body));

      if (!!tempBody.properties.languageId === false) {
        tempBody.properties.languageId = 1;
      }

      Object.preventExtensions(tempBody);
      return tempBody;
    } else if (
      typeof body === 'object' &&
      (body.languageId === undefined || body.languageId === null)
    ) {
      return (body = { ...body, languageId: 1 });
    } else return body;
  }
}
