import { CdkStepperModule } from '@angular/cdk/stepper';
import { AsyncPipe, CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { TranslocoModule } from '@ngneat/transloco';
import { DataTablesModule } from 'angular-datatables';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { TooltipModule } from 'primeng/tooltip';
import { AngularMaterialModule } from 'src/app/modules/angular-material.module';
import { AppAlertComponent } from './components/app-alert/app-alert.component';
import { AppButtonComponent } from './components/app-button/app-button.component';
import { AppCardComponent } from './components/app-card/app-card.component';
import { AppStatusComponent } from './components/app-status/app-status.component';
import { TooltipComponent } from './components/app-tooltip/tooltip.component';
import { ClientSideDataTableComponent } from './components/data-table/client-side-data-table/client-side-data-table.component';
import { AppCheckboxComponent } from './components/form-controls/app-checkbox/app-checkbox.component';
import { AppInputComponent } from './components/form-controls/app-input/app-input.component';
import { AppRadioComponent } from './components/form-controls/app-radio/app-radio.component';
import { AppSearchInputComponent } from './components/form-controls/app-search-input/app-search-input.component';
import { AppTextareaComponent } from './components/form-controls/app-textarea/app-textarea.component';
import { CustomListComponent } from './components/form-controls/custom-list/custom-list.component';
import { MultiDropDownComponent } from './components/form-controls/multi-drop-down/multi-drop-down.component';
import { MultiSelectAutocompleteComponent } from './components/form-controls/multi-select-autocomplete/multi-select-autocomplete.component';
import { SingleSelectAutocompleteComponent } from './components/form-controls/single-select-autocomplete/single-select-autocomplete.component';
import { AppTuiMultiSelectAutocompleteComponent } from './components/form-controls/tui-multi-select-autocomplete/app-tui-multi-select-autocomplete.component';
import { AppDialogComponent } from './components/modals/app-dialog/app-dialog.component';
import { PipesModule } from './pipes/pipes.module';
import { SharedStoreModule } from './store/module';
import { TAIGA_UI_MODULES } from './taiga.module';
import { AppBulkEditSingleValuePropertyComponent } from './components/bulk-edit/app-bulk-edit-single-value-property/app-bulk-edit-single-value-property.component';
import { AppBulkEditMultipleValuesPropertyComponent } from './components/bulk-edit/app-bulk-edit-multiple-values-property/app-bulk-edit-multiple-values-property.component';

@NgModule({
  declarations: [
    ClientSideDataTableComponent,
    AppInputComponent,
    MultiDropDownComponent,
    SingleSelectAutocompleteComponent,
    MultiSelectAutocompleteComponent,
    AppStatusComponent,
    AppDialogComponent,
    AppButtonComponent,
    AppAlertComponent,
    AppRadioComponent,
    AppCardComponent,
    CustomListComponent,
    AppTuiMultiSelectAutocompleteComponent,
    AppTextareaComponent,
    AppSearchInputComponent,
    TooltipComponent,
    AppCheckboxComponent,
    AppBulkEditSingleValuePropertyComponent,
    AppBulkEditMultipleValuesPropertyComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    CdkStepperModule,
    NgxMaskDirective,
    NgxMaskPipe,
    DataTablesModule,
    PipesModule,
    MatIconModule,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    AsyncPipe,
    MatChipsModule,
    TranslocoModule,
    SharedStoreModule,
    TooltipModule,
    TAIGA_UI_MODULES,
  ],
  exports: [
    ClientSideDataTableComponent,
    AppInputComponent,
    MultiDropDownComponent,
    SingleSelectAutocompleteComponent,
    MultiSelectAutocompleteComponent,
    AppStatusComponent,
    AppDialogComponent,
    AppButtonComponent,
    AppAlertComponent,
    AppRadioComponent,
    AppCardComponent,
    CustomListComponent,
    AppTuiMultiSelectAutocompleteComponent,
    AppTextareaComponent,
    AppSearchInputComponent,
    TooltipComponent,
    AppCheckboxComponent,
    AppBulkEditSingleValuePropertyComponent,
    AppBulkEditMultipleValuesPropertyComponent,
  ],
  providers: [
    provideNgxMask(),
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'none' },
    },
  ],
})
export class SharedModule {}
